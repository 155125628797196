import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { UIProductPricingGroup } from 'components/ui-product-layout-pricing-group/component';
import { Area, Location, MyHttpApi, PortionSize, PricingGroup, PricingGroupResponse, PrivilegeItem, Product, ProductCategory, ProductResponseWithPricingGroupRows, ProductSubCategory, ProductUpdateRequest, ProductUpdateRequestPP, SalesChannel, SalesRestriction, StorageUnit } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

class UIProductProduct {
	productCategory?: ProductCategory = undefined;
	productSubCategory?: ProductSubCategory = undefined;
	productResponse?: ProductResponseWithPricingGroupRows = undefined;

	@computedFrom("portionSizeList", "productResponse.productPricingGroupList", "productResponse.pricingGroupRowList")
	get productPortionSizeList() {
		const portionSizeList = this.portionSizeList.filter(x => !x.deleteTime && !x.supersededById);
		if (!this.productResponse) {
			return portionSizeList;
		}
		return portionSizeList.filter(ps => this.productResponse!.productPricingGroupList.map(ppg => ppg.portionSizeId).includes(ps.id) || this.productResponse!.pricingGroupRowList.map(x => x.portionSizeId).includes(ps.id));
	}

	/** Call after toggling productProduct.useSlavePrice */
	updateTotal() {
		if (this.productProduct.useSlavePrice && this.productResponse) {
			const pg = this.productResponse.productPricingGroupList.find(x => x.id === this.productProduct.productPricingGroupId);
			const total = pg?.price;
			const portionSizeId = pg?.portionSizeId;
			this.productProduct.total = total;
			this.productProduct.portionSizeId = portionSizeId;
		}
		if (!this.productProduct.useSlavePrice) {
			this.productProduct.productPricingGroupId = undefined;
		}
	}

	/** Call if master updated */
	updateMaster() {
		if (this.masterProduct.aliasType == 'PRODUCT_CATEGORY') {
			this.productProduct.productPricingGroupId = undefined;
		}
		if (this.masterProduct.useSlavePrice && !this.productProduct.useSlavePrice) {
			this.productProduct.total = undefined;
		}
		if ((this.masterProduct.useSlavePortion && !this.productProduct.useSlavePrice) || this.openPrice) {
			this.productProduct.portionSizeId = undefined;
		}
	}

	onPortionChange(oldValue: unknown, newValue: unknown) {
		let pgrList = this.productResponse?.productPricingGroupList.filter(pg => pg.portionSizeId === newValue);
		if (pgrList?.length == 1) {
			const pg = pgrList[0];
			this.productProduct.total = pg.price;
		}
	}

	@computedFrom("masterProduct.aliasType")
	get useSlavePriceDisabled() {
		return this.masterProduct.aliasType == 'PRODUCT_CATEGORY';
	}

	@computedFrom("masterProduct.useSlavePrice", "productProduct.useSlavePrice")
	get totalDisabled() {
		return (this.masterProduct.useSlavePrice || this.productProduct.useSlavePrice);
	}

	@computedFrom("totalDisabled")
	get totalRequired() {
		return !this.totalDisabled;
	}

	@computedFrom("productResponse.product.openPrice")
	get openPrice() {
		return this.productResponse?.product.openPrice || false;
	}

	@computedFrom("openPrice", "productProduct.useSlavePrice")
	get pricingGroupDisabled() {
		return this.openPrice || !this.productProduct.useSlavePrice;
	}

	@computedFrom("masterProduct.useSlavePortion", "productProduct.useSlavePrice")
	get portionSizeDisabled() {
		return this.masterProduct.useSlavePortion || this.productProduct.useSlavePrice;
	}

	selectProductPricingGroup(ppc?: UIProductPricingGroup) {
		this.productProduct.productPricingGroupId = ppc?.id;
	}

	constructor(
		public masterProduct: ProductUpdateRequest,
		public productCategoryList: ProductCategory[],
		public productSubCategoryList: ProductSubCategory[],
		public productResponseList: ProductResponseWithPricingGroupRows[],
		public portionSizeList: PortionSize[],
		public salesChannelList: SalesChannel[],
		public salesRestrictionList: SalesRestriction[],
		public productProduct: ProductUpdateRequestPP,
		public extraPortionSize?: PortionSize,
	) {
		this.productCategory = productCategoryList.find(pc => pc.id === this.productProduct.productCategoryId);
		this.productSubCategory = productSubCategoryList.find(pc => pc.id === this.productProduct.productSubCategoryId);
		this.productResponse = productResponseList.find(pr => pr.product.id === this.productProduct.productId);
	}
}


@autoinject
export class PosAliasEdit {
	private product: ProductUpdateRequest = {
		abbreviation: "",
		baseUnit: 'PCS',
		doNotAllowComponentsToMerge: false,
		ean: [],
		locationId: undefined,
		name: "",
		useSlavePortion: false,
		useSlavePrice: false,
		openPrice: false,
		productPricingGroupList: [],
		productCategoryId: "",
		openName: false,
		productProductList: [],
		useSalesChannel: false,
		productType: "ALIAS",
		aliasType: 'PRODUCT',
		useChosenProductOnBill: false,
		useChosenProductOnOrderList: false,
		useChosenProductOnPos: false,
		warehousedOnly: false,
		delete: false,
		type: 'CLIENT'
	};
	private newProduct: { product?: Product; productId?: string; } = {};
	private newProductCategory: { productCategoryId?: string, productSubCategoryId?: string; } = {};
	private productProduct: UIProductProduct[] = [];
	private productResponseMap: { [key: number]: ProductResponseWithPricingGroupRows; } = {};
	private productResponseList: ProductResponseWithPricingGroupRows[] = [];
	private regularProductList: Product[] = [];
	private pricingGroupResponseList: PricingGroupResponse[] = [];
	private imageBlob?: string = undefined;
	private privilege?: PrivilegeItem;
	private typeAndName = "";
	private productCategoryList: ProductCategory[] = [];
	private productCategoryMap: { [key: string]: ProductCategory; } = {};
	private productSubCategoryMap: { [key: string]: ProductSubCategory; } = {};

	private productSubCategoryList: ProductSubCategory[] = [];
	private portionSizeList: PortionSize[] = [];
	private salesChannelList: SalesChannel[] = [];
	private salesChannelMap: { [key: string]: SalesChannel; } = {};
	private salesRestrictionList: SalesRestriction[] = [];
	private pricingGroupList: PricingGroup[] = [];
	private extraProductCategory?: ProductCategory;
	private extraProductSubCategory?: ProductSubCategory;
	private extraSalesChannel?: SalesChannel = undefined;
	private extraSalesRestriction?: SalesRestriction = undefined;
	private locationList: Location[] = [];
	private areaList: Area[] = [];
	private storageUnitList: StorageUnit[] = [];
	private fullSalesChannelList: SalesChannel[] = [];
	private fullStorageUnitList: StorageUnit[] = [];
	private fullPortionSizeList: PortionSize[] = [];

	private locations: { [key: string]: Location; } = {};
	private areas: { [key: string]: Area; } = {};
	private portionSizes: { [key: string]: PortionSize; } = {};
	private salesRestrictions: { [key: string]: SalesRestriction; } = {};
	private salesChannels: { [key: string]: SalesChannel; } = {};
	private storageUnits: { [key: string]: StorageUnit; } = {};
	productLocationId? = "";
	private disabled = false;

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
	}

	async activate(params: { id?: string; }) {
		this.privilege = getPrivilege();
		if (params.id) {
			let [product, image] = await Promise.all([this.api.productByIdExtended({ id: params.id }), this.api.productImageById({ id: params.id })]);
			this.product = {
				...product.product,
				productType: product.product.type,
				delete: !!product.product.deleteTime,
				type: this.privilege?.type || 'CLIENT',
				productPricingGroupList: [],
				productProductList: product.productProductList,
			};
			this.productLocationId = product.product.locationId;

			this.privilege = privilegeItemFromElement(product.product);
			this.product.image = image?.imageData;
			this.imageBlob = image?.imageData && "data:" + image.imageMimeType + ";base64," + image.imageData;
		}

		let [productCategoryList, productSubCategoryList, storageUnitList, portionSizeList, salesChannelList, salesRestrictionList, productResponseList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.storageUnitList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productListWithPricingGroupRows({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
		]);
		
		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime);
		this.portionSizeList = portionSizeList.filter(x => !x.deleteTime);
		this.salesChannelList = salesChannelList.filter(x => !x.deleteTime);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime);
		this.storageUnitList = storageUnitList.filter(x => !x.deleteTime);
		this.productResponseList = productResponseList;

		this.fullPortionSizeList = portionSizeList;
		this.fullSalesChannelList = salesChannelList;
		this.fullStorageUnitList = storageUnitList;

		this.portionSizes = MyHttpApi.toHashStr(portionSizeList);
		this.salesChannels = MyHttpApi.toHashStr(salesChannelList);
		this.salesRestrictions = MyHttpApi.toHashStr(salesRestrictionList);
		this.storageUnits = MyHttpApi.toHashStr(storageUnitList);

		this.extraProductCategory = this.productCategoryMap[this.product.productCategoryId];
		this.extraProductSubCategory = this.product.productSubCategoryId ? this.productSubCategoryMap[this.product.productSubCategoryId] : undefined;
		this.extraSalesChannel = this.product.salesChannelId ? this.salesChannelMap[this.product.salesChannelId] : undefined;
		this.extraSalesRestriction = this.product.salesRestrictionId ? this.salesRestrictions[this.product.salesRestrictionId] : undefined;

		this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		let pricingGroupResponseList = await this.api.pricingGroupListWithRows({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
		this.pricingGroupResponseList = pricingGroupResponseList.filter(x => !x.pricingGroup.deleteTime && !x.pricingGroup.supersededById);
		this.pricingGroupList = this.pricingGroupResponseList.map(x => x.pricingGroup);
		let productProduct = this.product.productProductList.map(x => new UIProductProduct(this.product,
			productCategoryList, productSubCategoryList, productResponseList, portionSizeList, salesChannelList, salesRestrictionList, x, portionSizeList.find(ps => ps.id === x.portionSizeId)));
		this.productProduct = productProduct;

		if (this.privilege?.id) {
			let canEdit = await this.api.privilegeCanEdit(this.privilege);
			if (this.privilege?.id && this.privilege.type === 'CLIENT') {
				let [locationList, areaList, bgr] = await Promise.all([
					this.api.locationList({ id: this.privilege.id }),
					this.api.areaList({ id: this.privilege.id }),
					this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id }),
				]);
				this.locationList = locationList.filter(x => !x.deleteTime && (!this.productLocationId || this.productLocationId === x.id));
				this.areaList = areaList.filter(x => !x.deleteTime);
				this.locations = MyHttpApi.toHashStr(this.locationList);
				this.areas = MyHttpApi.toHashStr(this.areaList);
				canEdit = bgr.clientSpecificAliases;
			}
			this.disabled = !canEdit;
		}

		let regularProductList = productResponseList.map(pr => pr.product).filter(p => p.type === "REGULAR" && !p.deleteTime);
		// * Should really be computed, as we now can face a situation, where main-product location-id changes, and we already have bad location data on rows?
		regularProductList.forEach(p => p.locationId && (p.name += " / " + this.locations[p.locationId]?.name));
		this.regularProductList = regularProductList.filter(x => !x.locationId || x.locationId === this.product.locationId);
	}

	@computedFrom("privilege.type", "product.id", "productLocationId", "locationList.length")
	get canModifyLocation() {
		return !!(this.privilege?.type === 'CLIENT' && ((!this.product.id && this.locationList.length > 1) || this.productLocationId));
	}

	@computedFrom("newProductCategory.productCategoryId", "productSubCategoryList")
	get productSubCategoryListDynamic2() {
		let productCategoryId = this.newProductCategory.productCategoryId;
		return this.productSubCategoryList.filter(pc => productCategoryId != undefined && pc.productCategoryId === productCategoryId);
	}

	@computedFrom("product.productCategoryId")
	get productCategory() {
		return this.productCategoryList.find(x => this.product.productCategoryId == x.id);
	}

	_buildRequest(): ProductUpdateRequest | undefined {
		let obj = { ...this.product };
		obj.productProductList = this.productProduct.map(x => ({ ...x.productProduct }));
		if (!obj.productProductList.length) {
			this.notify.info("product.noAliasProduct", {});
			return undefined;
		}
		const hasProductAlias = obj.productProductList.find(x => x.productId);
		const hasProductCategoryAlias = obj.productProductList.find(x => x.productCategoryId);
		if (hasProductAlias && hasProductCategoryAlias) {
			this.notify.info("server.aliasTypeMixup", {});
			return undefined;
		}
		return obj;
	}

	@computedFrom("disabled")
	get disabledText() {
		if (this.disabled) {
			return this.i18n.tr('businessGroupRestriction.readOnly');
		}
		return "";
	}

	async save(deleted: boolean) {
		let request = this._buildRequest();
		if (!request) {
			return;
		}
		await this.api.productUpdate({
			...request,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted
		});
		this.router.navigateBack();
	}

	async toggleDelete() {
		let obj = this._buildRequest();
		if (!obj) {
			return;
		}
		await this.save(!obj.delete);
	}

	setProductProduct() {
		this.newProduct.product = this.regularProductList?.find(p => p.id === this.newProduct.productId);
	}

	addPP() {
		if (this.newProduct.product) {
			this.productProduct.push(new UIProductProduct(this.product,
				this.productCategoryList, this.productSubCategoryList, this.productResponseList, this.portionSizeList, this.salesChannelList, this.salesRestrictionList, {
				useSlavePrice: false,
				productId: this.newProduct.product.id,
			}),);
			this.newProduct = {};
		}
		if (this.newProductCategory.productCategoryId) {
			this.productProduct.push(new UIProductProduct(this.product,
				this.productCategoryList, this.productSubCategoryList, this.productResponseList, this.portionSizeList, this.salesChannelList, this.salesRestrictionList, {
				useSlavePrice: false,
				productCategoryId: this.newProductCategory.productCategoryId,
				productSubCategoryId: this.newProductCategory.productSubCategoryId,
			}));
			this.newProductCategory = {};
		}
	}

	removePP(pp: UIProductProduct) {
		const index = this.productProduct.indexOf(pp);
		if (index !== -1) {
			this.productProduct.splice(index, 1);
		}
	}

	@computedFrom("privilege.type")
	get showLocationAndArea() {
		return this.privilege?.type === 'CLIENT';
	}

	/* Automatically fill in abbreviation. If the name is 1 char longer or shorter and shares a common root, then overwrite abbreviation. */
	@computedFrom("product.name")
	get nameObserver() {
		let name = this.product.name || "";
		let abbreviation = this.product.abbreviation || "";
		if (Math.abs(name.length - abbreviation.length) <= 1 && name.substring(0, Math.min(name.length, abbreviation.length)) === abbreviation.substring(0, Math.min(name.length, abbreviation.length))) {
			this.product.abbreviation = name.substring(0, 40); // generated from next()
		}
		return "";
	}

	clearImage() {
		this.product.image = undefined;
		this.imageBlob = undefined;
	}

	async productImageOnLoadHandler(event: Event) {
		const inputElement = event.target;
		const files = (<HTMLInputElement>inputElement).files;
		if (!files) {
			return;
		}
		const file = files[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = async () => {
			const res = reader.result;
			if (typeof res === "string") {
				const imageData = res.split(",", 2)[1];
				let imageIsOk = await this.api.imageTest({ image: imageData });
				if (!imageIsOk) {
					this.product.image = undefined;
					this.notify.info("admin.imageTestNotOk", {});
					return;
				}
				this.imageBlob = res;
				this.product.image = imageData;
			}
		};
		reader.readAsDataURL(file);
	}

	@computedFrom('product.baseUnit', 'storageUnitList.length')
	get storageUnitListDynamic() {
		let baseUnit = this.product.baseUnit;
		return this.storageUnitList.filter(su => !baseUnit || su.baseUnit === baseUnit);
	}

	@computedFrom('product.productCategoryId', 'productSubCategoryList.length')
	get productSubCategoryListDynamic() {
		let productCategoryId = this.product.productCategoryId;
		const productCategory = this.productCategoryList.find(x => x.id == productCategoryId);
		const productSubCategory = this.productSubCategoryList.find(x => x.id == this.product.productSubCategoryId);
		if (this.product.productSubCategoryId && productSubCategory?.productCategoryId !== productCategoryId) {
			this.product.productSubCategoryId = undefined; // generated from next()
			this.extraProductSubCategory = undefined;
		}
		if (productCategory) {
			this.product.baseUnit = productSubCategory?.defaultBaseUnit || productCategory.defaultBaseUnit;
			this.product.storageUnitId = productSubCategory?.defaultStorageUnitId || productCategory.defaultStorageUnitId;
		}
		return this.productSubCategoryList.filter(pc => productCategoryId != undefined && pc.productCategoryId === productCategoryId);
	}

	@computedFrom("pricingGroupList")
	get defaultPricingGroupList(): PricingGroup[] {
		return this.pricingGroupList.filter(x => x.isDefault);
	}

	areaListDynamic(locationId: string) {
		return this.areaList.filter(x => x.locationId === locationId);
	}

	@computedFrom("product.baseUnit")
	get baseUnitObserver() {
		let storageUnit = this.storageUnitList.find(su => su.baseUnit === this.product.baseUnit);
		if (!storageUnit) {
			this.product.storageUnitId = undefined;
		}
		return "";
	}

	@computedFrom("product.productCategoryId", "product.productSubCategoryId")
	get categoryObserver() {
		const productSubCategory = this.productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
		if (!productSubCategory) {
			this.product.productSubCategoryId = undefined;
		}
		return "";
	}
}
